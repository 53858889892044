import { createSelector } from 'reselect';

import { State } from '../../../store';
import { DeliveryType, RatesState, StepType } from '../state';

import { sum } from '../../../helpers/numbers';

const getRatesState = ({ rates }: State): RatesState => rates;

export const getActiveStep = createSelector(getRatesState, ({ activeStep }) => activeStep);
export const isRuleAccepted = createSelector(getRatesState, ({ isRulesAccepted }) => isRulesAccepted);
export const showFilters = createSelector(getRatesState, ({ activeStep }) => activeStep === StepType.search);

// declarations
export const getDeclarations = createSelector(getRatesState, ({ declarations }) => declarations);
export const getDeclarationFullPrice = createSelector(getDeclarations, (declarations) => sum(declarations, 'price'));
export const getDeclarationFullWeight = createSelector(getDeclarations, (declarations) => sum(declarations, 'weight'));

// form values
export const getDeliveryType = createSelector(getRatesState, ({ deliveryType }) => deliveryType);
export const isCourierDelivery = createSelector(
  getDeliveryType,
  (deliveryType) => deliveryType === DeliveryType.courier
);
export const getSendingMethod = createSelector(getRatesState, ({ sendingMethod }) => sendingMethod);
export const getEmail = createSelector(getRatesState, ({ email }) => email);

// sender data
export const getSender = createSelector(getRatesState, ({ sender }) => sender);
export const getReceiver = createSelector(getRatesState, ({ receiver }) => receiver);

// zip modal
export const showZipModal = createSelector(getRatesState, ({ modals }) => modals.zipCode);

export const getSenderDepartmentID = createSelector(getSender, ({ department }) =>
  department && department.alfred_id ? department.alfred_id : null
);

export const getReceiverPostCode = createSelector(getReceiver, ({ postCode }) => postCode);
export const getReceiverPostCodeHasCourier = createSelector(
  getRatesState,
  ({ postCodeService }) => postCodeService.courier
);
