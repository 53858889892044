export const scrollToFormError = () => {
  const form = document.querySelector<HTMLFormElement>('form.el-form');

  if (form) {
    const withoutAlerts = !document.querySelector('.el-alert.el-alert--error');

    if (withoutAlerts) {
      // find first form error element
      const formItem = form.querySelector<HTMLDivElement>('.el-form-item.is-error');

      if (formItem) {
        // scroll to first item
        formItem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });

        return;
      }
    }

    // just scroll at the form start
    form.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  }
};
