import { createSelector } from 'reselect';

import * as fromRates from '../rates/selectors';
import * as fromProviders from './selectors';

import { getProviderService, hasDocs, isInPostGBInstant, isInPostLocker, isSF } from '../../config/providers';

import { ParcelDeclaration } from '../rates';
import { Dimensions, Declaration, Parcels, ShipmentsBody } from '../../client';

import { equals, sum } from '../../helpers/numbers';
import * as fromExts from '../rates/selectors/extensions.selectors';

export const ratesModelSelector = createSelector(
  fromRates.getSendingMethod,
  fromRates.getEmail,
  fromRates.getSender,
  fromRates.getReceiver,
  fromRates.getDeclarations,
  (method, email, sender, receiver, declarations) => ({
    sender_sendMethod: method,
    sender_email: email,
    sender_dropOffPoint: sender.dropOffPoint,
    sender_phone: sender.phone,
    sender_fixedPhone: sender.fixedPhone || undefined,
    sender_firstName: sender.firstName,
    sender_lastName: sender.lastName,
    sender_street: sender.street,
    sender_houseName: sender.houseName,
    sender_buildingNumber: sender.building,
    sender_apartments: sender.apartments,
    sender_city: sender.city || '',
    sender_postCode: sender.postCode || '',
    sender_dispatchDate: sender.dispatchDate,
    sender_region: sender.region || '',

    // receiver
    receiver_phone: receiver.phone,
    receiver_firstName: receiver.firstName,
    receiver_lastName: receiver.lastName,
    receiver_street: receiver.street,
    receiver_buildingNumber: receiver.building,
    receiver_apartments: receiver.apartments,
    receiver_city: receiver.city || '',
    receiver_postCode: receiver.postCode || '',
    receiver_region: receiver.region,

    declarations: declarations as ParcelDeclaration[],
  })
);

export const getParcelParams = createSelector(
  fromProviders.getParcelWidth,
  fromProviders.getParcelHeight,
  fromProviders.getParcelLength,
  (width, height, length): Dimensions => ({
    width: width * 10,
    height: height * 10,
    length: length * 10,
    unit: 'mm',
  })
);

const getCountries = createSelector(
  fromProviders.getDispatchCountry,
  fromProviders.getInboundCountry,
  fromProviders.getBookService,
  ({ code: from, provider }, { code: to }, bookService) => {
    const service = getProviderService(provider, bookService);

    return {
      from: from.toUpperCase(),
      to: to.toUpperCase(),
      service,
    };
  }
);

export const isWeightValid = createSelector(
  fromProviders.getParcelWeight,
  fromRates.getDeclarations,
  (weight, declarations) => equals(weight, sum(declarations, 'weight'))
);

export const getParcels = createSelector(
  fromRates.getDeclarations,
  fromProviders.geParcelTemplate,
  getParcelParams,
  (declarations, template, dimensions) => {
    const parcel: Parcels = {
      weight: {
        amount: sum(declarations, 'weight'),
        unit: 'kg',
      },
    };

    if (template) {
      parcel.template = template.type;
    } else {
      parcel.dimensions = dimensions;
    }

    return parcel;
  }
);

export const getDropOffPoint = createSelector(
  fromProviders.getDispatchProvider,
  fromRates.getSender,
  fromProviders.showDropOffMap,
  fromProviders.shouldHardCodeDropOff,
  ({ extra }, { dropOffPoint }, isMapVisible, hardCode) => {
    // if has dropOffPoint in form
    if (dropOffPoint) {
      return dropOffPoint;
    }

    // if should hard code
    if (hardCode) {
      return extra.dropOffPoint || '';
    }

    if (isMapVisible && extra.dropOffPoint) {
      return extra.dropOffPoint;
    }

    return '';
  }
);

export const parcelHasDocs = createSelector(
  fromProviders.getDispatchCountry,
  fromProviders.getBookService,
  fromProviders.isWithoutDocs,
  ({ provider }, service, noDocs) => {
    // for InPost locker use `no docs` checkbox
    if (isInPostLocker(provider, service)) {
      return !noDocs;
    }

    // for InPost UK Instant use `no docs` checkbox
    if (isInPostGBInstant(provider, service)) {
      return false;
    }

    // for SmartForwarding use `no docs` checkbox
    if (isSF(provider)) {
      return noDocs;
    }

    return hasDocs(provider, service);
  }
);

export const getShipmentsBody = createSelector(
  fromRates.getDeliveryType,
  ratesModelSelector,
  getCountries,
  getParcels,
  getDropOffPoint,
  parcelHasDocs,
  fromExts.getActiveExtensions,
  fromRates.getSenderDepartmentID,
  fromProviders.getInboundCountry,
  (
    deliver_type,
    rates,
    countries,
    parcels,
    dropOffPoint,
    withDocs,
    extensions,
    point_id,
    destination
  ): ShipmentsBody => ({
    deliver_type,
    no_docs: !withDocs,
    max: !parcels.template,
    service: countries.service,
    custom_attributes: {
      sending_method: rates.sender_sendMethod,
      dropoff_point: dropOffPoint,
    },
    point_id,
    value: sum(rates.declarations, 'price'),
    extensions,
    dispatch_date: rates.sender_dispatchDate,
    parcels,
    declaration: rates.declarations.map(
      (dec: ParcelDeclaration, id: number): Declaration => ({
        id,
        description: dec.description,
        count: dec.count,
        price: dec.price,
        weight: dec.weight,
      })
    ),
    sender: {
      first_name: rates.sender_firstName,
      last_name: rates.sender_lastName,
      phone: rates.sender_phone,
      fixed_phone: rates.sender_fixedPhone,
      email: rates.sender_email,
      address: {
        city: rates.sender_city,
        street: rates.sender_street,
        apartments: rates.sender_apartments,
        house_name: rates.sender_houseName,
        building_number: rates.sender_buildingNumber,
        country_code: countries.from,
        post_code: rates.sender_postCode,
        region: rates.sender_region,
      },
    },
    receiver: {
      first_name: rates.receiver_firstName,
      last_name: rates.receiver_lastName,
      phone: rates.receiver_phone,
      address: {
        city: rates.receiver_city,
        street: rates.receiver_street,
        apartments: rates.receiver_apartments,
        building_number: rates.receiver_buildingNumber,
        country_code: countries.to,
        post_code: rates.receiver_postCode,
        region: rates.receiver_region,
      },
    },
    deliver_country: destination.code,
  })
);
