import { Shipment } from '../../client/client.types';
import { ExtensionPrice } from '../../client/extensions';
import { Department } from '../../client';

export enum StepType {
  search,
  form,
  payment,
}

export enum DeliveryType {
  courier = 'courier',
  department = 'department',
}

export enum SendingMethod {
  parcelLocker = 'parcel_locker',
  courierPickUp = 'dispatch_order',
  InPostAccessPoint = 'pok',
  InPostOffice = 'branch',
}

export interface ParcelDeclaration {
  id?: number;
  description: string;
  count: string | number;
  weight: string | number;
  price: string | number;
}

export type ExtensionTypes = 'insurance';

export interface Extension {
  id: number;
  name_en: string;
  name_ru: string;
  name_ua: string;
  name_pl: string;
  slug: ExtensionTypes;
  percent_price: number | null;
  fixed_price: number | null;
}

const state = {
  activeStep: StepType.search,
  isRulesAccepted: false,
  deliveryType: DeliveryType.department,
  sendingMethod: '',
  email: '',
  sender: {
    firstName: '',
    lastName: '',
    street: '',
    houseName: '',
    building: '',
    apartments: '',
    city: '',
    postCode: '',
    phone: '',
    fixedPhone: '',
    dropOffPoint: '',
    showMap: false,
    dispatchDate: '',
    region: '',
    department: {} as Department | null,
  },
  receiver: {
    firstName: '',
    lastName: '',
    street: '',
    building: '',
    apartments: '',
    city: '',
    postCode: '',
    phone: '',
    region: '',
  },
  declarations: [
    {
      description: '',
      count: '',
      price: '',
      weight: '',
    },
  ] as ParcelDeclaration[],
  extensions: {} as Record<ExtensionTypes, Extension>,
  activeExt: {} as Partial<Record<ExtensionTypes, number>>,
  extensionsPrice: {} as Record<ExtensionTypes, ExtensionPrice>,
  postCodeService: {
    courier: false,
  },
  shipment: null as Shipment | null,
  modals: {
    zipCode: false,
  },
};

export const initialState = Object.freeze(state);

export type RatesState = typeof state;
