import { createSelector } from 'reselect';

import { State } from '../../../store';
import { RatesState } from '../state';

import { getDispatchCountry } from '../../providers/selectors';

const getRatesState = ({ rates }: State): RatesState => rates;

export const geShipment = createSelector(getRatesState, ({ shipment }) => shipment);
export const geShipmentPrice = createSelector(geShipment, getDispatchCountry, (shipment, { currency, provider }) =>
  shipment
    ? {
        provider,
        hash: shipment.crypto_hash,
        shipmentID: shipment.id,
        price: shipment.price,
        localCurrency: currency,
        priceEUR: shipment.price_eur,
      }
    : null
);
