import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeliveryType, Extension, ExtensionTypes, initialState, SendingMethod, StepType } from './state';
import * as selectors from './selectors';

import { Shipment } from '../../client/client.types';
import { ExtensionPrice } from '../../client';

export * from './state';
export { selectors };

const ratesSlice = createSlice({
  name: 'rates',
  initialState,
  reducers: {
    fullReset() {
      return { ...initialState };
    },
    resetState(state) {
      state.sender = { ...initialState.sender };
      state.receiver = { ...initialState.receiver };
      state.sendingMethod = initialState.sendingMethod;
      state.declarations = [...initialState.declarations];
      state.email = initialState.email;
      state.deliveryType = initialState.deliveryType;
      state.isRulesAccepted = initialState.isRulesAccepted;
      state.shipment = initialState.shipment;
    },
    setShipment(state, { payload: { shipment } }: PayloadAction<{ shipment: Shipment }>) {
      state.shipment = shipment;
    },
    setStep(state, { payload: { step } }: PayloadAction<{ step: StepType }>) {
      state.activeStep = step;
    },
    setRulesAccept(state, { payload: { accepted } }: PayloadAction<{ accepted: boolean }>) {
      state.isRulesAccepted = accepted;
    },
    setDelivery(state, { payload: { delivery } }: PayloadAction<{ delivery: DeliveryType }>) {
      state.deliveryType = delivery;
    },
    setSendMethod(state, { payload: { method } }: PayloadAction<{ method: SendingMethod }>) {
      state.sendingMethod = method;
    },
    setEmail(state, { payload: { email } }: PayloadAction<{ email: string }>) {
      state.email = email;
    },
    setSender(state, { payload }: PayloadAction<Record<string, string>>) {
      state.sender = {
        ...state.sender,
        ...payload,
      };
    },
    setReceiver(state, { payload }: PayloadAction<Record<string, string>>) {
      state.receiver = {
        ...state.receiver,
        ...payload,
      };
    },
    addDeclaration(state) {
      state.declarations.push(...initialState.declarations);
    },
    removeDeclaration(state, { payload: { position } }: PayloadAction<{ position: number }>) {
      state.declarations = state.declarations.filter((_, idx) => idx !== position);
    },
    updateDeclaration(
      state,
      { payload: { idx, key, value } }: PayloadAction<{ key: string; idx: number; value: string | number }>
    ) {
      // @ts-ignore
      state.declarations[idx][key] = value;
    },
    fetchExtensions(state) {
      state.extensions = {} as never;
      state.activeExt = {} as never;
      state.extensions = {} as never;
    },
    fetchExtensionsSuccess(
      state,
      { payload: { extensions = {} as never } }: PayloadAction<{ extensions: Record<ExtensionTypes, Extension> }>
    ) {
      state.extensions = extensions;
    },
    checkExt(state, { payload: { ext, emit = true } }: PayloadAction<{ ext: ExtensionTypes; emit?: boolean }>) {
      if (emit) {
        state.activeExt[ext] = state.extensions[ext].id;
      }
    },
    unCheckExt(state, { payload: { ext } }: PayloadAction<{ ext: ExtensionTypes }>) {
      delete state.activeExt[ext];
    },
    fetchServicePrice(state, { payload: { service } }: PayloadAction<{ service: ExtensionTypes }>) {
      state.extensionsPrice[service] = {
        price: '',
        localPrice: '',
        localDeclarationPrice: '',
        localMax: '',
        maxValue: '',
        isHigher: false,
      };
    },
    updateServicePrice(
      state,
      { payload: { service, price } }: PayloadAction<{ service: ExtensionTypes; price: ExtensionPrice }>
    ) {
      state.extensionsPrice[service] = price;
    },
    fetchPostCodeCourier(state) {
      state.postCodeService.courier = false;
    },
    fetchPostCodeServiceSuccess(state, { payload: { courier } }: PayloadAction<{ courier?: boolean }>) {
      if (typeof courier !== 'undefined') {
        state.postCodeService.courier = courier;
      }
    },
    setZipModal(state, { payload: { zipCode } }: PayloadAction<{ zipCode: boolean }>) {
      state.modals.zipCode = zipCode;
    },
  },
});

export const { actions, reducer } = ratesSlice;

export default ratesSlice;
