import { createSelector } from 'reselect';

import { State } from '../../../store';
import { ExtensionTypes } from '../state';

import * as fromProviders from '../../providers/selectors';

import { simpleSum } from '../../../helpers/numbers';
import { extractPriceValue, priceCurrencyToSign } from '../../../helpers/currency';

const getExtensionsState = ({ rates }: State) => rates.extensions;
const getExtensionsPriceState = ({ rates }: State) => rates.extensionsPrice;
const getActiveExtensionsState = ({ rates }: State) => rates.activeExt;

export const getExtensions = createSelector(getExtensionsState, (extensions) => extensions || {});

export const getActiveExtensions = createSelector(getActiveExtensionsState, (extensions) =>
  Object.values(extensions).map(Number)
);

export const getExtPriceState = (service: ExtensionTypes) =>
  createSelector(getExtensionsPriceState, (prices) => prices[service] || {});

export const getIsHigherPrice = (service: ExtensionTypes) =>
  createSelector(getExtPriceState(service), ({ isHigher = false }) => isHigher);

export const getExtPrice = (service: ExtensionTypes, inEUR = false) =>
  createSelector(getExtPriceState(service), ({ localPrice = '', price = '' }) => (inEUR ? price : localPrice));

export const getExtFullPrice = (service: ExtensionTypes) =>
  createSelector(
    getExtPrice(service),
    fromProviders.getParcelPriceWithDisc,
    fromProviders.getCurrentCurrency,
    (extPrice, parcelPrice, cur) => `${simpleSum([extPrice, parcelPrice].map(extractPriceValue))} ${cur}`
  );

export const getExtMaxCompensations = (service: ExtensionTypes) =>
  createSelector(getExtPriceState(service), (prices) => {
    const maxEUR = priceCurrencyToSign(prices.maxValue);
    const localValue = priceCurrencyToSign(prices.localDeclarationPrice);

    return `${localValue} ${prices.isHigher ? `(${maxEUR})` : ''}`.trim();
  });
